import * as React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Masonry from 'react-masonry-css';
import { OverviewPageQuery } from '../../graphql-types';
import './index.scss';
import { ProjectPreviewCard } from '../components/projectPreviewCard';
import { PreviewImage } from '../components/previewImage';

interface IProps {
  data: OverviewPageQuery;
}
const Index: React.FC<IProps> = (props) => {
  // const pageMeta = React.useMemo(
  //   () => ({
  //     title: prismicHomePage.data.seo_attributes[0].page_title,
  //     description: prismicHomePage.data.seo_attributes[0].page_description,
  //     keywords: prismicHomePage.data.seo_attributes[0].keywords,
  //   }),
  //   [allPrismicNews]
  // );

  const breakpointColumnsObj = {
    default: 4,
    1500: 3,
    1000: 2,
    700: 1,
  };

  const items = props.data.prismic.allOverviews.edges[0].node.items;
  return (
    <Layout
      pageMeta={{
        title: 'Evie Harbury Food Stylist',
        description:
          'Evie (Evie) Harbury finished her training at Le Cordon Bleu, London in 2014 and has since been working as a chef, food stylist, recipe developer and home economist.',
        keywords: 'Chef, Food Stylist, Home Economist',
        image: {
          src: items[0].image.url,
          height: items[0].image.dimensions.height,
          width: items[0].image.dimensions.width,
        },
      }}
    >
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {items.map((item) => (
          <PreviewImage image={item.imageSharp.childImageSharp.fluid} />
        ))}
      </Masonry>
    </Layout>
  );
};

export const pageQuery = graphql`
  query OverviewPage {
    prismic {
      allOverviews {
        edges {
          node {
            title
            items {
              image
              imageSharp {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Index;

// item {
//   ... on PRISMIC_Project {
//     name
//     description
//     transition_colour
//     preview_image {
//       default_image
//       default_imageSharp {
//         childImageSharp {
//           fluid {
//             ...GatsbyImageSharpFluid_noBase64
//           }
//         }
//       }
//       hover_image
//       hover_imageSharp {
//         childImageSharp {
//           fluid {
//             ...GatsbyImageSharpFluid_noBase64
//           }
//         }
//       }
//       hover_transition
//     }
//     _meta {
//       uid
//     }
//   }
//   _linkType
//   __typename
// }
